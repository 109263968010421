import { getLoginQRCode, checkQRStatus, userInfo, getOtherSet } from "@/api/index";
import Header from "@/views/main-components/header";
import Footer from "@/views/main-components/footer";
import LangSwitch from "@/views/main-components/lang-switch";
import util from "@/libs/util.js";
import QRCode from "qrcode";
import Cookies from 'js-cookie';
export default {
  components: {
    LangSwitch,
    Header,
    Footer
  },
  data() {
    return {
      loading: false,
      checkToken: "",
      // 二维码状态 初始为0
      qrStatus: "0",
      qrUrl: "",
      checkInterval: null
    };
  },
  methods: {
    init() {
      this.getQRCode();
    },
    getQRCode() {
      getLoginQRCode().then(res => {
        if (res.success) {
          this.qrStatus = "0";
          let url = res.result;
          this.generateQR(url);
          this.checkToken = url.substring(url.indexOf("=") + 1, url.length);
          this.checkLoginQRStatus();
        }
      });
    },
    generateQR(v) {
      QRCode.toDataURL(v, {
        margin: 0
      }, (err, url) => {
        if (err) {
          this.$Message.error("生成二维码图片失败");
        }
        this.qrUrl = url;
      });
    },
    checkLoginQRStatus() {
      // 3秒检测一次二维码状态
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
      }
      this.checkInterval = setInterval(() => {
        checkQRStatus(this.checkToken).then(res => {
          if (res.success) {
            this.qrStatus = res.result.status;
            if (this.qrStatus < "0") {
              clearInterval(this.checkInterval);
            }
            if (this.qrStatus == "2") {
              let accessToken = res.result.accessToken;
              this.$Message.success("扫码登录成功");
              this.setStore("accessToken", accessToken);
              this.afterLogin(accessToken);
            }
          }
        });
      }, 3000);
    },
    afterLogin(accessToken) {
      getOtherSet().then(res => {
        if (res.result) {
          let domain = res.result.ssoDomain;
          Cookies.set("accessToken", accessToken, {
            domain: domain,
            expires: 7
          });
        }
      });
      // 获取用户信息
      userInfo().then(res => {
        if (res.success) {
          // 避免超过大小限制
          delete res.result.permissions;
          let roles = [];
          res.result.roles.forEach(e => {
            roles.push(e.name);
          });
          delete res.result.roles;
          this.setStore("roles", roles);
          this.setStore("saveLogin", this.saveLogin);
          if (this.saveLogin) {
            // 保存7天
            Cookies.set("userInfo", JSON.stringify(res.result), {
              expires: 7
            });
          } else {
            Cookies.set("userInfo", JSON.stringify(res.result));
          }
          this.setStore("userInfo", res.result);
          this.$store.commit("setUserInfo", res.result);
          // 加载菜单
          util.initRouter(this);
          // window.location.reload();
          this.$router.push({
            name: "home_index"
          });
        } else {
          this.loading = false;
        }
      });
    },
    submitLogin() {}
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }
};